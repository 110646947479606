<template>
	<!-- 底部弹窗 -->
	<div class="sheet">
		<van-action-sheet v-model="show" title=" ">
			<div class="content">
				<div class="top">
					<div style="display: flex;align-items: center;padding-left: 20px;" >
							<van-image width="40" height="40" radius='50%' :src="ProfilePicture" class="img" />
							<span style="margin-left: 10px;" >{{ChildrenName}}</span>		
					</div>
				</div>
				<div class="selectBtn">
					<div class="my-card" @click='dailyRecordShow'>
						<div class="top">
							<img  src="../../../../../assets/home/8.png" style="width: 30px;height: 30px;"/>
							<span>成长记录</span>
						</div>
						<div class="bottom">
							<p>教师寄语:阶段性评价</p>
							<p>点滴记录:徽章记录</p>
						</div>
					</div>
					<div class="my-card" @click="enterGrowDetails">
						<div class="top">
							<img  src="../../../../../assets/home/6.png" style="width: 30px;height: 30px;"/>
							<span>查看成长册</span>
						</div>
						<div class="bottom">
							<p>查看教师寄语</p>
							<p>查看点滴记录</p>
						</div>
					</div>
				</div>	
			</div>
		</van-action-sheet>
		
		<!-- 成长记录底部组件 -->
		<bottomBar ref="bottomBar" :ChildrenID='ChildrenID' :ChildrenName='ChildrenName' :ProfilePicture='ProfilePicture'></bottomBar>
		
	</div>

</template>

<script>
	import {
		ActionSheet,
		Image as VanImage,
	} from "vant";
	
	import bottomBar from  '@/components/common/bottomBar/index.vue'
	
	export default {
		data() {
			return {
				show: false,
				ProfilePicture:'',
				ChildrenName:'',
				ChildrenID:''
			}
		},
		components: {
			bottomBar,
			[ActionSheet.name]: ActionSheet,
			[VanImage.name]: VanImage
		},
		methods: {
			// 打开成长记录弹窗
			dailyRecordShow(){
				this.$refs.bottomBar.show = true
			},
			// 进入成长册详情
			enterGrowDetails(){
				this.$router.push({
					path: '/growthManualList/childenDetails',
					query: {
						id: this.ChildrenID
					}
				})
			}	
		}
	}
</script>

<style lang="less" scoped>
	.sheet {
		padding: 10px;

		.content {
			padding: 16px 2px;
			.selectBtn{
				display: flex;
				justify-content: space-around;
				margin-top: 20px;
				padding: 0 10px;
				.my-card {
					border: 1px solid #ebedf0;
					border-radius: 10px;
					width: 36%;
					.top {
						display: flex;
						align-items: center;
						span {
							margin-left: 20px;
							color: #000000;
							font-weight: bold;
						}
					}
					.bottom {
						color: #a6a6a6;
						margin-top: 10px;
						p:last-child{
							margin-top: 4px;
							font-size: 12px;
						}
					}
				}
			}
		}
	}
</style>
