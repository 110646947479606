<template>
	<!-- 学生列表 -->
	<div class="growthManualList">
		<div class="containerCard" >
			<div class="text" v-show="ClassName">
				<span>{{ClassName}}</span>
			</div>
			<!-- 滚动区域 -->
			<div class="scollView" style="flex: 1;overflow:auto;">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh" success-text="刷新成功">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="_getMoreChildrens"
						offset="100">

						<div class="my-card" v-for="item in ChildrenGrowthDtos" :key="item.ChildrenID"
							@click='childenDetails(item)'>
							<div class="left">
								<van-image width="40" height="40" radius='10' :src="item.ProfilePicture" />
							</div>
							<div class="right">
								<!-- 顶部 -->
								<div class="topInfo">
									<span
										style="font-size: 16px;color: #000000;font-weight: bold;">{{item.ChildrenName}}</span>
									<span>
										<img src="../../../../assets/home/20.png" style="width: 20px;height: 20px;" />
										<img src="../../../../assets/home/19.png"
											style="width: 20px;height: 20px;margin: 0 5px;" />
										<span>{{item.BadgeRecordCount}}</span>
									</span>
								</div>
								<!-- 内容 -->
								<div class="content">
									<div class="contetnTeacher">
										<div class="leftBox">
											<img src="../../../../assets/home/teacher.png"
												style="width: 30px;height: 16px;" />
											<span style="color: #a6a6a6;">寄语</span>
											<span>{{item.TeacherEvaluationCount}}条</span>
										</div>
										<van-divider style="width: 10px;color: red;margin: 0 4px;"></van-divider>
										<div class="rightBox">
											<span style="color: #a6a6a6;">在园记录</span>
											<span>{{ClassCircleCount}}条</span>
										</div>
									</div>
									<div class="contetnParent">
										<div class="leftBox">
											<img src="../../../../assets/home/parent.png"
												style="width: 30px;height: 16px;" />
											<span style="color: #a6a6a6;">寄语</span>
											<span>{{item.ParentEvaluationCount}}条</span>
										</div>
										<van-divider style="width: 10px;color: red;margin: 0 4px;"></van-divider>
										<div class="rightBox">
											<span style="color: #a6a6a6;">在家记录</span>
											<span>{{item.ChildrenCircleCount}}条</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</van-list>
				</van-pull-refresh>
			</div>
			<!-- 底部弹窗 -->
			<bottomSheet ref="bottomSheet"></bottomSheet>
		</div>
	</div>
</template>

<script>
	import {
		Divider,
		List,
		PullRefresh,
		Image as VanImage,
		ActionSheet,
		Empty
	} from "vant";

	import bottomSheet from './bottomSheet/index.vue'


	export default {
		name: 'GrowthManualList',
		components: {
			bottomSheet,
			[Divider.name]: Divider,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			[VanImage.name]: VanImage,
			[ActionSheet.name]: ActionSheet,
			[Empty.name]: Empty
		},
		data() {
			return {
				userType: window.localStorage.getItem("UserType"),
				form: {
					content: '',
					uploadList: [], //上传图片
					circleType: '日常动态' //默认日常动态
				},
				showPicker: false,
				ChildrenGrowthDtos: [], //card数据
				ClassName: '', //班级
				ClassCircleCount: '', //在园记录
				loading: false,
				finished: false, // false
				refreshing: false,
				params: {
					page: 0,
					limit: 5
				},
			}
		},
		mounted() {
			//this._getChildrens()
		},
		methods: {
			// 获取学生数据
			_getChildrens() {
				this.$axios.get('/api/ChildrenGrowthManual/teacher-childrenGrowthManuals',
					this.params
				).then(res => {
					if (this.refreshing) {
						this.refreshing = false
					}
					if (res.code == 200) {
						this.loading = false;
						if (res.data) {
							this.ClassName = res.data.ClassName
							this.ClassCircleCount = res.data.ClassCircleCount
							this.ChildrenGrowthDtos.push(...res.data.ChildrenGrowthDtos)
							if (this.ChildrenGrowthDtos.length >= res.count) {
								this.finished = true;
							}
						} else {
							this.finished = true;
						}

					}
				})
			},
			// 上拉加载更多
			_getMoreChildrens() {
				this.params.page++
				this._getChildrens()
			},
			// 下拉刷新
			onRefresh() {
				this.params.page = 1;
				// 清空列表数据
				this.finished = false
				this.loading = true
				this.ChildrenGrowthDtos = []
				this._getChildrens()
			},
			// 打开底部弹窗
			childenDetails(item) {
				this.$refs.bottomSheet.ProfilePicture = item.ProfilePicture
				this.$refs.bottomSheet.ChildrenName = item.ChildrenName
				this.$refs.bottomSheet.ChildrenID = item.ChildrenID
				this.$refs.bottomSheet.show = true
			}
		}
	}
</script>

<style lang="less" scoped>
	.growthManualList {
		height: 100%;
		background-color: white;

		.containerCard {
			background-image: linear-gradient(#47AFA7, #ffffff);
			padding: 0px 10px;
			height: 100%;
			display: flex;
			flex-direction: column;

			.text {
				font-size: 16px;
				color: #000000;
				font-weight: bold;
				position: relative;
				height: 3%;
				margin-top: 32px;

				::after {
					display: inline-block;
					content: '';
					height: 5px;
					border-radius: 10px;
					width: 52px;
					// background-color: #f0a000;
					position: absolute;
					bottom: 0px;
					left: 50%;
					transform: translate(-50%, 0);
					opacity: 0.5;
				}
			}
		}

		/deep/ .van-divider {
			transform: rotate(90deg);
			border-color: #a6a6a6;
		}

		.text {
			text-align: center;
			margin: 10px 0px;
		}

		.my-card {
			display: flex;
			margin-top: 4px;

			.left {
				width: 12%;
				display: flex;
				align-items: center;
				justify-content: left;
			}

			.right {
				flex: 1;
				margin-left: 10px;

				.topInfo {
					display: flex;
					padding: 0 5px;
					justify-content: space-between;
					align-items: center;

					span {
						display: flex;
						align-items: center;
					}
				}

				.content {
					padding: 0 5px;

					.contetnTeacher,
					.contetnParent {
						margin: 10px 0;
						display: flex;

						.leftBox {
							display: flex;
							align-items: center;
							width: 32%;
							justify-content: space-between;
						}

						.rightBox {
							width: 32%;
							display: flex;
							align-items: center;
							justify-content: space-between;
						}
					}
				}
			}
		}
	}
</style>
